

var endpoint = '/gender'
export default class genderService {

  constructor(axios){
    this.axios = axios
  }

  list(params) {
    return this.axios.get(`${endpoint}/`, { params })
  }
  

  get(id) {
    return this.axios.get(`${endpoint}/${id}`)
  }
}
