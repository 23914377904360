<script>
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import PatientService from "@/services/patient-service";
import GenderService from "@/services/gender-service";
import CorreiosService from "@/services/correios-service";
import PaymentTypeService from "@/services/paymenttype-service";

/**
 * Patients Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    
  },
  data() {
    return {
      
      patientId: null,
      title: this.$t("all.patients"),
      items: [
        {
          text: this.$t("all.admin"),
        },
        {
          text: this.$t("all.patients"),
          active: true,
        },
      ],
      patient: {
        address: {
          address: null,
          complement: null,
        },
        gender: null,
        aba: false,
        active: true,
      },
      submitted: false,
      paymentTypes: [],
      genders: [],
      
    }
  },

  validations: {
    patient: {
      name: { required },
      birth: { required },
      gender: { required },
      paymentType: { required },
    },
  },


  created() {
    
    this.service = new PatientService(this.$http);
    this.paymentTypeService = new PaymentTypeService(this.$http);
    this.genderService = new GenderService(this.$http);
    this.correiosService = new CorreiosService(this.$http);
    this.patientId = this.$route.query.patientId
    if (this.patientId) {
      this.charge(this.patientId);
    }
    this.chargePaymentTypes();
    this.chargeGender();
    
  },
  methods: {
    age(birthDate, full) {
      
      let now = new Date();
      birthDate = new Date(Date.parse(birthDate));
      var years = now.getFullYear() - birthDate.getFullYear();
      var months = now.getMonth() - birthDate.getMonth();
      var days = now.getDate() - birthDate.getDate();
      if (months < 0 || (months == 0 && days < 0)) {
        years--;
        months += days < 0 ? 11 : 12;
      }

      if (days < 0) {
        let monthAgo = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          birthDate.getDate()
        );
        days = Math.floor((now - monthAgo) / (1000 * 60 * 60 * 24));
      }
      if (full) {
        return years + " anos e " + months + " meses";
      }
      return years + "a" + months + "m" + days + "d";
    },
    charge(id) {
      this.loading = true;
      this.service.get(id).then((res) => {
        console.log(res);
        this.patient = res.data;
        let date = new Date(Date.parse(this.patient.birth));
        this.patient.birth = date;
        console.log(this.patient)
        if (!this.patient.address) {
          this.patient.address = {
            address: ""
          }
        }
        this.loading = false;
      }),
        (err) => {
          console.error(err);
        };
    },
    chargePaymentTypes() {
      
      console.log("chargePaymentTypes")
      this.paymentTypeService.list({ size: 100 }).then((res) => {

        console.log("listPaymenttypes", res);
        this.paymentTypes = res.data.content;
        console.log(this.paymentTypes);
      }),
        (err) => {
          console.error(err);
        };
    },
    chargeGender() {
      
      console.log("chargeGender")
      this.genderService.list({ size: 100 }).then((res) => {

        console.log("chargeGender", res);
        this.genders = res.data.content;
        console.log(this.genders);
      }),
        (err) => {
          console.error(err);
        };
    },

    showPaymentType(paymentType) {
      if (paymentType) {
        return paymentType.name + " - (" + paymentType.type + ")"
      } else {
        return "";
      }
    }
    ,
    edit(patient) {
      //TODO: implementar
      console.log(patient)
    },
    cancel() {
      this.returnToPatient();
    },
    printer() {

      const url = `/admin/patient/print/${this.patientId}`;
      window.open(url, '_blank');
    },
    returnToPatient() {
      this.$router.push(`/admin/patient`);
    },
    getCep() {
      
      var cep = this.patient.address.zipCode;

      if (cep.length === 9) {

        this.correiosService.getByZipCode(cep.replaceAll('-', '')).then(ret => {
          if (!ret.data) {
            this.$toast.error("CEP não encontrado");
            return
          }

          this.patient.address.address = ret.data.address
          this.patient.address.city = ret.data.city
          this.patient.address.state = ret.data.ufCode
          this.patient.address.district = ret.data.neighborhood

          this.patient.x = (this.patient.x ? this.patient.x : 0) + 1
          this.$toast.success("Endereço obtido pelo CEP");

        });
      }


    },
    /**
     * Form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.error("Formulário com dados Inválidos ou Faltando");
        return;
      } else {
        const id = this.patient.id;
        const patient = this.patient;
        if (patient.address.zipCode && patient.address.address == "") {
          patient.address = null
        }

        this.service
          .save(
            patient, id)
          .then((ret, err) => {
            console.log(ret);
            if (err) {
              this.$toast.error("Erro ao tentar gravar dados");
            }
            this.$toast.success("Dados gravados com sucesso");

            this.returnToPatient();
          });
      }
      this.submitted = false;
    },

  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('all.patient')" :items="items" />
    
    <div class="row col-lg-12">
      <form @submit.prevent="handleSubmit" class="col-lg-12">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div>
                <div v-if="loading" class="d-flex justify-content-center"></div>
              </div>

              <div class="form-group row">
                
                <label class="col-md-2 col-form-label" for="patactive">{{ $t("all.status") }}</label>

                <b-checkbox id="patactive" class="col-md-2" v-model="patient.active">
                  {{ $t("active." + patient.active) }}
                </b-checkbox>
                

                <label class="col-md-1 col-form-label" for="pataba">{{ $t("all.isABA") }}</label>

                <b-checkbox id="pataba" v-model="patient.aba">
                  {{ $t("isABA." + patient.aba) }}
                </b-checkbox>
                
              </div>

              <div class="form-group row">
                <!-- Nome -->
                <label class="col-md-2 col-form-label"> Nome * </label>
                <input id="name" v-model="patient.name" type="text" class="form-control col-md-6"
                  placeholder="Enter name" autocomplete="off"
                  :class="{ 'is-invalid': submitted && $v.patient.name.$error }" />
                <div v-if=" !$v.patient.name.required" class="invalid-feedback">
                  Name is required
                </div>
                <label v-if="patient.birth" class="col-md-4 col-form-label"> Idade: {{ age(patient.birth, true) }}
                </label>

              </div>
              <div class="form-group row">
                <!-- Genero -->
                <label class="col-md-2 col-form-label">
                  Genero *</label>
                <select class="col-md-4 custom-select" v-model="patient.gender"
                  :class="{ 'is-invalid': submitted && $v.patient.gender.$error }">
                  <option v-for="(gender, i) in genders" :key="i" :value="gender">
                    {{ gender.value }}
                  </option>
                </select>
                <div v-if="submitted && !$v.patient.gender.required" class="invalid-feedback">
                  Gender is required
                </div>
              </div>
              <div class="form-group row">

                <!-- Data Nascimento -->
                <label class="col-md-2 col-form-label" :class="{ 'text-danger': submitted && $v.patient.birth.$error }">
                  Dt. Nasc.* </label>
                <date-picker v-model="patient.birth" format="DD/MM/YYYY" value-type="date" type="date" class="col-md-4"
                  :class="{ 'is-invalid text-danger': submitted && $v.patient.birth.$error }"></date-picker>
                <div v-if="submitted && !$v.patient.birth.required" class="invalid-feedback">
                  Dt.Nasc is required
                </div>
              </div>
              <div class="form-group row">
                <!-- CPF -->
                <label class="col-md-2 col-form-label"> CPF * </label>
                <input id="cpf" v-model="patient.cpf" type="text" class="form-control col-md-4" placeholder="CPF"
                  autocomplete="off" />
                <!-- RG -->
                <label class="col-md-2 col-form-label"> RG </label>
                <input id="rg" v-model="patient.rg" type="text" class="form-control col-md-4" placeholder="RG"
                  autocomplete="off" />
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label" for="exampleInputAnotations">Pagamento *</label>
                <select class="form-control col-md-4" v-model="patient.paymentType" :class="{
      'is-invalid': submitted && $v.patient.paymentType.$error,
    }">
                  <option>Selecione forma de Pagamento</option>
                  <option v-for="(payment, i) in paymentTypes" :key="i" :value="payment">
                    {{ showPaymentType(payment) }}
                  </option>
                </select>

                <div v-if="submitted && $v.patient.paymentType.$error" class="invalid-feedback">
                  <span v-if="!$v.patient.paymentType.required">Pagamento is required</span>
                </div>
              </div>
              <div class="form-group row">
                <!-- Annotation -->
                <label class="col-md-2 col-form-label"> Observações </label>
                <textarea id="cpf" v-model="patient.anotations" type="textarea" class="form-control col-md-10"
                  placeholder="" autocomplete="off" lines="2" />
              </div>

              <div class="form-group row">
                <!-- diagnosis -->
                <label class="col-md-2 col-form-label"> Diagnóstico </label>
                <textarea id="cpf" v-model="patient.diagnosis" type="textarea" class="form-control col-md-10"
                  placeholder="" autocomplete="off" lines="2" />
              </div>
              <!--Endereço -->
              <b-card no-body class="mb-3">
                <b-card-header header-tag="header" v-b-toggle.accordion-3 role="tab">
                  <h6 class="m-0">
                    <a v-b-toggle.accordion-3 class="text-dark" href="javascript: void(0);">{{ $t("all.address") }} </a>
                  </h6>

                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="col-md-12">
                  <b-card-body class="row col-md-12">
                    <div class="form-group col-md-12 row">
                      <!-- CEP -->
                      <label class="col-md-2 col-form-label"> CEP </label>
                      <input id="cep" v-model="patient.address.zipCode" @keyup="getCep" v-mask="'#####-###'" type="text"
                        class="form-control col-md-3" placeholder="00000-000" autocomplete="off" />
                    </div>

                    <div class="form-group col-md-12 row">
                      <!-- Rua -->
                      <label class="col-md-2 col-form-label"> Rua </label>
                      <input id="rua" v-model="patient.address.address" type="text" class="form-control col-md-6"
                        placeholder="Rua/Av ...." autocomplete="off" />
                      <!-- Numero -->
                      <label class="col-md-2 col-form-label"> Número </label>
                      <input id="numero" v-model="patient.address.addressNumber" type="text"
                        class="form-control col-md-2" placeholder="" autocomplete="off" />

                    </div>
                    <div class="form-group col-md-12 row">
                      <!-- complemento -->
                      <label class="col-md-2 col-form-label"> Complemento </label>
                      <input id="complemento" v-model="patient.address.complement" type="text"
                        class="form-control col-md-4" placeholder="Apt/Bloco" autocomplete="off" />
                      <!-- Bairro -->
                      <label class="col-md-2 col-form-label"> Bairro </label>
                      <input id="cep" v-model="patient.address.district" type="text" class="form-control col-md-4"
                        placeholder="Bairro" autocomplete="off" />

                    </div>

                    <div class="form-group col-md-12 row">
                      <!-- Estado -->
                      <label class="col-md-2 col-form-label"> Estado </label>
                      <input id="complemento" v-model="patient.address.state" type="text" class="form-control col-md-4"
                        placeholder="SP" autocomplete="off" />
                      <!-- Cidade -->
                      <label class="col-md-2 col-form-label"> Cidade </label>
                      <input id="cidade" v-model="patient.address.city" type="text" class="form-control col-md-4"
                        placeholder="cidade" autocomplete="off" />

                    </div>





                  </b-card-body>
                </b-collapse>
              </b-card>
              <!--Responsável -->
              <!-- <b-card no-body class="mb-3">
                      <b-card-header header-tag="header" v-b-toggle.contact-2 role="tablist">
                        <h6 class="m-0">
                          <a
                            v-b-toggle.contact-2
                            class="text-dark"
                            href="javascript: void(0);"
                            >{{ $t("all.contact")}}  </a
                          >
                        </h6>
                      
                      </b-card-header>
                      <b-collapse
                        id="contact-2"
                        accordion="my-contact"
                        role="tabpanel"
                      >
                        <b-card-body class="row">
                          
                         xxxx
                          
                          
                        </b-card-body>
                      </b-collapse>
                    </b-card> -->
              <!--Botoes-->
              
              <b-card-footer>
                <button type="submit" class="btn btn-success">
                  {{ $t("all.save") }}
                </button>
                <b-button class="ml-1" variant="danger" @click="cancel">
                  {{ $t("all.cancel") }}
                </b-button>
                <b-button v-show="patientId" class="ml-1" variant="primary" @click="printer">
                  {{ $t("all.printer") }}
                </b-button>
              </b-card-footer>
            </div>
          </div>
        </div>
      </form>
       <!-- Modal Loading-->
       <b-modal id="modal-loadin" class="modal-dialog modal-sm" :hide-footer="true" v-model="loading">
      <b-spinner style="width: 3rem; height: 3rem;"  class="m-2 spinner-border text-primary"><!----></b-spinner>
        Aguarde

      </b-modal>
    </div>

  </Layout>
</template>