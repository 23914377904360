
var endpoint = '/cep'

export default class CorreiosService {

  constructor(axios){
    this.axios = axios
  }
 
  getByZipCode(zipCode) {
    zipCode.replaceAll('-', '');
    return this.axios.get(`${endpoint}/${zipCode}`);
  }
}
